import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _debounce from "lodash/debounce";
import SearchItemPill from "../../components/searchitem-pill";
import _size from "lodash/size";
import * as actions from "./actions";
import * as styled from "./style";
import { colors } from "../../common/styles/variables";
import tooltipConstants from "../../config/content/tooltip";
import HelpTooltip from "../tooltip-search";
import JaanpErrorIcon from "../../assets/images/jaanp-error.svg";

const debounceDelay = 300;
const cursorDefault = -1

const mapStateToProps = state => {
  return {
    autoSuggest: state.autoSuggestReducer.autoSuggest,
    toolTipIndex: state.homeReducer.toolTipIndex
  };
};

/**
 * @description Auto Suggest Search component
 * @class AutoSuggestSearch
 * @extends {Component}
 * @exports AutoSuggestSearch
 */
export class AutoSuggestSearch extends Component {

  static propTypes = {
    resources: PropTypes.shape({
      Placeholder_txt: PropTypes.string,
      button_txt: PropTypes.string,
      icon: PropTypes.string,
      clearIcon: PropTypes.string
    }),
    onSearchClick: PropTypes.func
  };

  state = {
    showSuggestions: false,
    showClearButton: false,
    userInput: "",
    cursor: cursorDefault,
    searchedText: "",
    suggestionForTour: false,
    keywordInput: "",
    showInputClearButton: false,
    searchData: [],
    searchError: false,
    isSearchEnabled: false,
    screenWidth: null,
    suggestionSelected: false,
    termError: false
  };

  constructor() {
    super();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  suggestionWrapper = null;

  inputField = null;

  inputFieldMobile = null;

  searchDebounce = _debounce((data) => {
    this.props.getAutoSuggest(data);
  }, debounceDelay);

  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClickHandler);
    // eslint-disable-next-line no-empty-function
    this.setState = () => { };
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }

  componentDidMount() {
    const { value, tourStatus, searchQueryData, keywordInput, updateSearchData } = this.props;
    const { searchData, searchError } = this.state;
    if (value) {
      this.setState({
        userInput: value,
        showClearButton: true
      });
    }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("click", this.onWindowClickHandler);
    if (searchQueryData && searchQueryData.length > 0) {
      this.setState({
        searchData: searchQueryData,
        isSearchEnabled: true
      })
    }
    if (keywordInput && keywordInput.length > 0) {
      this.setState({
        keywordInput: keywordInput,
        showInputClearButton: true
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { userInput, searchData, isSearchEnabled, searchError, keywordInput, showSuggestions, screenWidth, termError } = this.state;
    const { searchQueryData, checkError, updateSearchData, searchDataUpdate } = this.props;
    if (this.searchInput && this.searchPillWrapper.children.length == 0) {
      this.searchInput.style.width = "95%";
      if (window.innerWidth < 1024) {
        this.keywordWrapper.style.height = "50px";
        this.inputWrapper.style.height = "50px";
      } else {
        this.keywordWrapper.style.height = "70px";
        this.inputWrapper.style.height = "70px";
      }
    } else if (this.searchInput && this.searchPillWrapper.children.length > 0) {
      if (this.searchPillWrapper.clientWidth < ( (50 / 100) * this.inputWrapper.clientWidth)) {
        this.searchInput.style.width = ((this.inputWrapper.clientWidth - this.searchPillWrapper.clientWidth) - 15 ) + "px"
      } else {
        this.searchInput.style.width = "95%";
      }
      if (window.innerWidth >= 1366) {
        this.keywordWrapper.style.height = "70px";
        if ((this.searchInput.offsetWidth + this.searchPillWrapper.offsetWidth) > 415) {
          this.inputWrapper.style.height = "auto";
        }
        else {
          this.inputWrapper.style.height = "70px";
        }
      }
      else if (screenWidth >= 1024 && screenWidth < 1366) {
        this.keywordWrapper.style.height = "70px";
        if ((this.searchInput.offsetWidth + this.searchPillWrapper.offsetWidth) > 345) {
          this.inputWrapper.style.height = "auto";
        }
        else {
          this.inputWrapper.style.height = "70px";
        }
      }
      else if (screenWidth >= 768 && screenWidth < 1024) {
        this.keywordWrapper.style.height = "50px";
        if ((this.searchInput.offsetWidth + this.searchPillWrapper.offsetWidth) > 350) {
          this.inputWrapper.style.height = "auto";
        }
        else {
          this.inputWrapper.style.height = "50px";
        }
      }
    }
    if (searchData.length > 0) {
      if (showSuggestions && this.suggestionWrapper) {
        this.suggestionWrapper.style.marginTop = (this.inputWrapper.offsetHeight + 7) + "px";
      }
    }
    if (searchQueryData && searchQueryData.length > 0 && searchQueryData !== searchData) {
      this.setState({
        searchData: searchQueryData,
        isSearchEnabled: true
      })
    }
    if (userInput.length === 0 && searchData.length === 0 && isSearchEnabled) {
      this.setState({
        isSearchEnabled: false
      })
    }
    if ((searchQueryData.length > 0 || searchData.length > 0) && searchError) {
      this.setState({
        searchError: false
      })
      checkError(false)
    }
    if (keywordInput && keywordInput.length > 0 && (searchData.length === 0 && searchQueryData.length === 0) && !searchError) {
      this.setState({
        searchError: true
      })
      checkError(true)
    }
    if (prevProps.searchTerm !== this.props.searchTerm) {
      this.setState({
        keywordInput: this.props.searchTerm
      })
    }
    if (prevProps.productTerm !== this.props.productTerm) {
      const url = new URLSearchParams(this.props.location.search);
      const query = url.get("query");
      const searchTerm = url.get("searchTerm");
      const updateSearchDataItem = {};
      if (query && query.length > 0) {
        updateSearchDataItem.userSearchInput = query;
        updateSearchDataItem.latestSearchData = [query];
        updateSearchDataItem.keywordInput = searchTerm;
        updateSearchData(updateSearchDataItem)
        searchDataUpdate(updateSearchDataItem.latestSearchData);
      } else {
        const productData = searchData;
        productData.push(this.props.productTerm.trim());
        updateSearchData(productData);
        searchDataUpdate(productData);
      }
    }
    if (searchQueryData && searchQueryData.length === 0 && termError) {
      this.setState({
        termError: false
      })
    }
  }


  /**
   * @description window click handler
   * @param {object} e event object
   * @returns {undefined}
   */
  onWindowClickHandler = e => {
    if (this.suggestionWrapper && this.inputField && !this.suggestionWrapper.contains(e.target) && e.target !== this.inputField) {
      this.setState({
        showSuggestions: false,
        cursor: cursorDefault
      });
    }
    if (this.suggestionWrapper && this.inputFieldMobile && !this.suggestionWrapper.contains(e.target) && e.target !== this.inputFieldMobile) {
      this.setState({
        showSuggestions: false,
        cursor: cursorDefault
      });
    }
  }

  /**
   * @param {string} inputVal user input
   * @returns {boolean} should auto suggest be displayed
   */
  shouldShowAutoSuggest = inputVal => (inputVal.trim().length > 2);

  /**
   * @description Input field on change handler
   * @param {object} e event object
   * @returns {undefined}
   */
  onInputChange = e => {
    const { value: inputVal } = e.target;
    const { searchData, isSearchEnabled } = this.state;
    const showAutoSuggest = this.shouldShowAutoSuggest(inputVal);
    if (showAutoSuggest) {
      const data = { "userInput": inputVal };
      this.searchDebounce(data);
    } else {
      this.props.setAutoSuggest();
    }
    this.setState({
      userInput: inputVal,
      searchedText: inputVal,
      cursor: cursorDefault,
      showSuggestions: showAutoSuggest,
      showClearButton: inputVal.trim().length > 0,
      suggestionSelected: showAutoSuggest
    });
  }

  onInputChangeKeyword = e => {
    const { value: inputVal } = e.target;
    this.setState({
      keywordInput: inputVal,
      showInputClearButton: inputVal.trim().length > 0
    });
  }

  /**
   * @description Input field on keydown handler
   * @param {object} e event object
   * @returns {undefined}
   */
  onInputKeydown = e => {
    const { userInput, cursor, showSuggestions, searchData } = this.state;
    const { autoSuggest, updateSearchData, searchDataUpdate, fromHomePage } = this.props;
    if (showSuggestions) {
      if (e.keyCode === 38 && cursor >= 0) {
        e.preventDefault();
        this.setState(prevState => {
          const newState = {
            cursor: prevState.cursor - 1
          }
          if (cursor === 0) {
            newState.userInput = this.state.searchedText
          }
          return newState
        }, () => {
          const [activeElm] = this.suggestionWrapper.getElementsByClassName("active-suggestion");
          if (activeElm) {
            const width = (activeElm.getAttribute("value").length * 10) > 210 ? (activeElm.getAttribute("value").length * 10) : 210;
            this.setState({ userInput: activeElm.getAttribute("value") });
            if ((this.suggestionWrapper.scrollTop - (activeElm.offsetHeight * parseInt(activeElm.id.slice(1))) + this.suggestionWrapper.offsetHeight)
              > this.suggestionWrapper.offsetHeight) {
              this.suggestionWrapper.scrollTop -= activeElm.offsetHeight;
            }
          }
        });
      }
      else if (e.keyCode === 40 && (autoSuggest.results && cursor < autoSuggest.results.length - 1)) {
        this.setState(prevState => ({
          cursor: prevState.cursor + 1
        }), () => {
          const [activeElm] = this.suggestionWrapper.getElementsByClassName("active-suggestion");
          if (activeElm) {
            const width = (activeElm.getAttribute("value").length * 10) > 210 ? (activeElm.getAttribute("value").length * 10) : 210;
            this.setState({ userInput: activeElm.getAttribute("value") });
            if ((activeElm.offsetHeight * parseInt(activeElm.id.slice(1)) + activeElm.offsetHeight + 2) >= this.suggestionWrapper.offsetHeight) {
              this.suggestionWrapper.scrollTop += activeElm.offsetHeight;
            }
          }
        });
      }
      else if (e.keyCode === 13 && userInput.trim().length > 0 && cursor >= 0) {
        const currentSearchData = [...searchData];
        currentSearchData.push(userInput);
        if (fromHomePage) {
          this.setState({
            searchData: currentSearchData,
            showSuggestions: false,
            isSearchEnabled: true,
            userInput: ""
          })
          updateSearchData(userInput);
          searchDataUpdate(currentSearchData);
          if (this.inputField) {
            this.inputField.focus();
          }
          else if (this.inputFieldMobile) {
            this.inputFieldMobile.focus();
          }
        }
        else {
          this.setState({ searchData: currentSearchData }, () => this.onSearchClick())
          updateSearchData(userInput);
          searchDataUpdate(currentSearchData);
        }
      }
    }
  }

  /**
   * @description Input field on focus handler
   * @param {object} e event object
   * @returns {undefined}
   */
  onInputFocus = e => {
    // setTimeout(() => {
    //   if (this.inputField) {
    //     if (window.innerWidth < 767) window.scrollTo(0, (window.pageYOffset + this.inputField.getBoundingClientRect().top - 140));
    //   }
    // }, 350);
    const showAutoSuggest = this.shouldShowAutoSuggest(e.target.value);
    this.setState({ showSuggestions: showAutoSuggest });
  }

  onInputKeywordFocus = e => {
    const { searchData, searchError } = this.state;
    const { checkError } = this.props;
    if (searchData && searchData.length === 0) {
      checkError(true);
    }
    if (!searchError && searchData && searchData.length === 0) {
      this.setState({
        searchError: true
      })
    }
  }

  /**
   * @description Clears input field
   * @param {boolean} forKeyword boolean
   * @returns {undefined}
   */
  clearInputField = (forKeyword) => {
    if (forKeyword === true) {
      this.setState({
        keywordInput: "",
        showInputClearButton: false
      })
    }
    else {
      this.setState({
        userInput: "",
        showSuggestions: false,
        showClearButton: false
      });
    }
  }

  /**
   * @description Suggest item click handler
   * @param {object} e event object
   * @returns {undefined}
   */
  onSuggestedItemClick = e => {
    const { autoSuggest, updateSearchData, searchDataUpdate, fromHomePage } = this.props;
    const { searchData } = this.state;
    const searchText = autoSuggest.results[e.target.id.slice(1)];
    this.setState({
      userInput: searchText,
      showSuggestions: false,
      suggestionSelected: false
    }, () => {
      // updateSearchData(searchText);
      // onSearchClick(searchText);
      //TODO add data to searcData
      const newSearchData = searchData;
      const duplicateValue = this.checkDuplicate(searchText);
      if (!duplicateValue) {
        newSearchData.push(searchText);
        this.setState({
          searchData: newSearchData
        })
        this.clearInputField();
        searchDataUpdate(newSearchData);
        if (fromHomePage === false) {
          this.onSearchClick(searchText);
        }
      }
      else {
        this.clearInputField();
      }
    });
  }

  checkDuplicate = (val) => {
    const { searchData } = this.state;
    let duplicateFound = false;
    searchData.map((ele) => {
      if (ele === val.trim()) {
        duplicateFound = true;
      }
    })
    return duplicateFound;
  }

  /**
   * @description Search button click handler
   * @returns {undefined}
   */
  onSearchClick = () => {
    const { searchData, keywordInput, userInput } = this.state;
    const { onSearchClick, updateSearchData, searchDataUpdate, checkTermError, fromHomePage } = this.props;
    let userSearchQuery = "";
    searchData.map((element) => {
      userSearchQuery = userSearchQuery + (userSearchQuery.length > 0 ? "|" : "") + element
    })
    userSearchQuery.trim();
    userSearchQuery = userSearchQuery + (userSearchQuery.length > 0 ? " " : "");
    const latestSearchData = searchData;
    const updateSearchDataItem = {};
    updateSearchDataItem.userSearchInput = userSearchQuery;
    updateSearchDataItem.latestSearchData = latestSearchData;
    updateSearchDataItem.keywordInput = keywordInput;
    userSearchQuery = userSearchQuery + (userSearchQuery.length > 0 ? " " : "") + keywordInput;
    if (userSearchQuery && latestSearchData && (userSearchQuery.length > 0 || latestSearchData.length > 0) && keywordInput.trim().length === 0) {
      this.setState(({
        termError: true
      }))
      checkTermError(true)
    }
    else {
      if ((userSearchQuery && latestSearchData && (userSearchQuery.length > 0 || latestSearchData.length > 0) && keywordInput.trim().length > 0)) {
        this.setState(({
          termError: false
        }))
        checkTermError(false)
      }
      if (userSearchQuery.trim().length > 0) {
        updateSearchData(updateSearchDataItem);
        onSearchClick(userSearchQuery.trim(), latestSearchData, keywordInput);
      }
      this.setState({
        userInput: "",
        suggestionSelected: false,
        termError: false
      })
      this.clearInputField();
      if (this.inputField) {
        this.inputField.focus();
      }
      else if (this.inputFieldMobile) {
        this.inputFieldMobile.focus();
      }
    }
  }

  /**
   * @description This method handles make search string bold functionality
   * @param {string} str input string
   * @param {number} inputStrLen input string length
   * @returns {undefined}
   */
  makeSearchedStringBold = (str, inputStrLen) => {
    // if (inputStrLen < str.length) {
    //   return `<span class='normal-text'>${str.substring(0, inputStrLen - index - 1)}</span>${str.substring(inputStrLen - index)}`;
    // } else {
    //   return `<span class='normal-text'>${str}</span>`;
    // }
    return `<span class='normal-text'>${str.substring(0, inputStrLen)}</span>${str.substring(inputStrLen)}`;
  }

  renderSuggestionList = () => {
    const { results } = this.props.autoSuggest;
    const { fromHomePage } = this.props;
    const { cursor, userInput } = this.state;
    const topMargin = this.inputWrapper ? this.inputWrapper.offsetHeight + 7 : 70;
    const inputStr = userInput.split("");
    let indexKey = true;
    let finalArr = [];
    const regex = /[A-Za-z]/;
    inputStr.forEach((ele, key) => {
      if (regex.test(ele) && indexKey) {
        finalArr = inputStr.slice(key);
        indexKey = false;
      }
    });
    if (results && results.length > 0) {
      return (
        <styled.SuggestionWrapper ref={elm => this.suggestionWrapper = elm} role="listbox" id="dd-auto-select"
          fromHomePage={fromHomePage} topMargin={topMargin}>
          {results.map((suggestion, index) => {
            return (
              <styled.SuggestionItem
                key={`suggestion${index}`}
                id={`s${index}`}
                tabIndex="0"
                className={cursor === index ? "active-suggestion" : null}
                value={suggestion}
                onClick={this.onSuggestedItemClick}
                dangerouslySetInnerHTML={{ __html: this.makeSearchedStringBold(suggestion, finalArr.length) }}
              />
            );
          })}
        </styled.SuggestionWrapper>
      )
    } else {
      return null;
    }
  }

  renderListForTour = () => {
    const result = ["saxenda", "28.5% salicylic acid", "sabril", "safyral"];
    const { fromHomePage } = this.props;
    return (
      <styled.SuggestionWrapper fromHomePage={fromHomePage} className="suggestionWrapper">
        {
          result.map((suggestion, index) => {
            return (
              <styled.SuggestionItem
                value={suggestion}
                key={index}
                className={index === 0 ? "active-suggestion" : null}
                dangerouslySetInnerHTML={{ __html: this.makeSearchedStringBold(result[index], 3) }}
              ></styled.SuggestionItem>
            )
          })
        }
      </styled.SuggestionWrapper>
    )
  }

  pillCloseClick = (val) => {
    const { pillCloseClick, fromHomePage, checkTermError } = this.props;
    if (fromHomePage) {
      this.setState(({
        termError: false
      }))
      checkTermError(false);
    }
    if (!fromHomePage) {
      pillCloseClick(val);
    }
  }

  /**
   * On keyword down function
   * @param {Event} e event
   * @returns {undefined}
   */
  onInputKeywordDown = e => {
    const { searchData } = this.state;
    if (e.keyCode === 13) {
      if (searchData && searchData.length > 0) {
        this.onSearchClick();
      }
    }
  }

  renderJaanpErrorMsg = () => {
    return (
      <styled.JaanpErrorPopup>
        <img src={JaanpErrorIcon} alt="error" />
        <p>There appears to be an error in the entry. Accuracy is crucial for effective results.
          Please re-enter the product name from the drop down list found in the search box. Thank you.</p>
      </styled.JaanpErrorPopup>
    )
  }

  render() {
    const { resources: { Placeholder_txt, button_txt, icon, clearIcon, clearIcon_alt_txt, Placeholder_forkeyword_txt, error_logo, error_logo_alt, error_message,
      error_message_search_term }, fromHomePage, resources, searchDataUpdate, history, error } = this.props;
    const { userInput, showSuggestions, suggestionSelected, showClearButton, suggestionForTour, keywordInput, showInputClearButton,
      searchData, isSearchEnabled, searchError, termError } = this.state;
    const fromSearchPage = !fromHomePage;

    return (
      <styled.MainContainer fromHomePage={fromHomePage} className="maincontainer">
        <styled.InputBoxDiv>
          {showSuggestions && this.renderSuggestionList()}
          <styled.InputBoxWrapper className="product-input" ref={elm => { this.inputWrapper = elm }} changeHeight={(searchData.length > 1)
              || (userInput.length > 30 && searchData.length > 0) ? true : false}>
            <styled.PillWrapper className="pillwrapper" ref={elm => { this.searchPillWrapper = elm }}>
              <SearchItemPill data={searchData}
                resources={resources}
                searchDataUpdate={(val) => searchDataUpdate(val)}
                pillCloseClick={(val) => this.pillCloseClick(val)}
                fromSearchPage={fromSearchPage}
              ></SearchItemPill>
            </styled.PillWrapper>
            <styled.SearchInputWrapper ref={elm => { this.searchInput = elm }}>
              <styled.SearchInput
                type="text"
                ref={elm => { this.inputField = elm }}
                placeholder={Placeholder_txt}
                value={userInput}
                onChange={this.onInputChange}
                onKeyDown={this.onInputKeydown}
                onFocus={this.onInputFocus}
                spellCheck={false}
                clearButton={showClearButton}
                autoFocus={window.innerWidth < 768 ? false : true}
                className="productSearch"
              />
            </styled.SearchInputWrapper>
          </styled.InputBoxWrapper>
          {this.props.showJaanpError && this.renderJaanpErrorMsg()}
          {<styled.ErrorDropWrapper fromHomePage={fromHomePage}>
            {
              suggestionSelected &&
                <React.Fragment>
                  <img src={error_logo} alt={error_logo_alt}></img>
                  <p>Drug name must be selected from the drop down menu in order to continue.</p>
                </React.Fragment>
            }</styled.ErrorDropWrapper>}
        </styled.InputBoxDiv>
        <styled.KeywordWrapper>
          <styled.BoxWrapper>
            <styled.KeywordBoxWrapper className="term-input" ref={elm => { this.keywordWrapper = elm }}>
              <styled.KeywordInput
                type="text"
                placeholder={Placeholder_forkeyword_txt}
                ref={elm => { this.inputField = elm }}
                value={keywordInput}
                onChange={this.onInputChangeKeyword}
                onFocus={this.onInputKeywordFocus}
                spellCheck={false}
                forKeyword={true}
                clearButton={showInputClearButton}
                autoFocus={false}
                autoComplete="off"
                onKeyDown={this.onInputKeywordDown}
                className="keywordSearch">
              </styled.KeywordInput>
            </styled.KeywordBoxWrapper>
            <styled.SearchButtonWrapper>
              <styled.SearchButton type="button" id="auto-suggest-search" onClick={this.onSearchClick} disabled={searchData.length === 0 || keywordInput.length === 0}>
                <span>{button_txt}</span>
              </styled.SearchButton>
            </styled.SearchButtonWrapper>
          </styled.BoxWrapper>
          <styled.KeyWordSearchWrapperMobile className="sdada">
            <styled.KeywordInputMobile className="term-input-mobile">
              <styled.SearchInput
                type="text"
                ref={elm => { this.inputFieldMobile = elm }}
                placeholder={Placeholder_forkeyword_txt}
                value={keywordInput}
                onChange={this.onInputChangeKeyword}
                onFocus={this.onInputKeywordFocus}
                spellCheck={false}
                forKeyword={true}
                clearButton={showInputClearButton}
                autoFocus={false}
                autoComplete="off"
                className="keywordSearchMobile"
                onKeyDown={this.onInputKeywordDown}
              />
            </styled.KeywordInputMobile>
            <styled.ButtonHelpSection>
              <styled.SearchButton className="mobileSearch" type="button" id="auto-suggest-search"
                onClick={this.onSearchClick} disabled={searchData.length === 0 || keywordInput.length === 0}>
                <span>{button_txt}</span><img src={icon} alt={button_txt} ></img>
              </styled.SearchButton>
              <styled.ToolTipWrapperMobile>
                <styled.HelpToolTipSection>
                  <HelpTooltip resources={tooltipConstants} history={history} searchError={error}></HelpTooltip>
                </styled.HelpToolTipSection>
              </styled.ToolTipWrapperMobile>
            </styled.ButtonHelpSection>
          </styled.KeyWordSearchWrapperMobile>
          <styled.PoweredSectionWrapper>
            <styled.ErrorWrapper>
              {
                (searchError || termError) &&
                <React.Fragment>
                  <img src={error_logo} alt={error_logo_alt}></img>
                  <p>{!termError ? error_message : error_message_search_term}</p>
                </React.Fragment>
              }
            </styled.ErrorWrapper>
          </styled.PoweredSectionWrapper>
        </styled.KeywordWrapper>
      </styled.MainContainer>
    )
  }
}

export default connect(
  mapStateToProps,
  actions
)(withRouter(AutoSuggestSearch));
import React, { Component } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import homeContent from "../../config/content/homePage";
import { Header, HeaderLabel } from "./styles";
import resources from "../../config/content/dashboardPage";
import store from "../../store/store";
// import { LOGOUT } from "../../containers/intermediate/actions";
import headerLogo from "../../assets/tour-images/Logo.png";
import searchIcon from "../../assets/images/search-icon.svg"
import { memberPortalUrl } from "../../config/memberportal.config";

const mapStateToProps = state => {
  return {
    toolTipIndex: state.homeReducer.toolTipIndex,
    tourStatus: state.homeReducer.tourStatus
  };
};

/**
 * Header Component
 * @class HeaderComponent
 * @extends {Component}
 * @exports HeaderComponent
 */
class HeaderComponent extends Component {

  state = {
    navExpanded: false,
    height: 270
  };

  componentDidMount() {
    window.addEventListener("resize", this.manageHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.manageHeight);
  }

  manageHeight = () => {
    const {height} = this.state;
    const windowHeight = window.innerHeight - 50;
    if (height !== windowHeight) {
      this.setState({ height: windowHeight });
    }
  }

  /**
   * Close the hamburger menu
   * @param {string} action action
   * @returns {undefined}
   */
  closeNav(action) {
    this.setState({ navExpanded: false });
    // if (action === LOGOUT)
    //   store.dispatch({
    //     type: LOGOUT
    //   });
  }

  /**
   * Open the hamburger menu
   * @returns {undefined}
   */
  setIsNavExpanded() {
    this.setState({ navExpanded: !this.state.navExpanded });
  }

  /**
   * Funtion to navigate to home page
   * @returns {undefined}
   */
  pathToHome() {
    this.setState({ navExpanded: false });
    this.props.history.push("/home");
  }

  /**
   * Displays current route name in case of mobile
   *
   * @param {string} path location pathname
   * @param {string} links links
   * @returns {string} location pathname
   */
  displayPathName(path, links) {
    const filteredList = links.filter(z => path.indexOf(z.link) >= 0);
    return (filteredList.length > 0 && filteredList[0].label) ? filteredList[0].label : "";
  }

  /**
   * Member portal redirection link click
   * @returns {undefined}
   */
  memberportallink = () => {
    window.open(memberPortalUrl, "__blank");
  }

  render() {
    const { isUserLoggedIn, authenticationDetails, location, tourStatus, toolTipIndex } = this.props;
    const { headerLinks, phactmi_desc_txt } = homeContent;
    const links = headerLinks;
    const {height} = this.state;
    const { pathname } = location;
    return (
      <Header isSearch={pathname.includes("/search")} changeZIndex = {tourStatus ? toolTipIndex === 7 ? true : false : false} maxheight={height}>
        <Container>
          <Navbar expand="xl" collapseOnSelect onToggle={() => this.setIsNavExpanded()} variant="dark" expanded={this.state.navExpanded} >
            {/* <Navbar.Brand as={Link} to='/home' onClick={() => this.closeNav()}>
            </Navbar.Brand> */}
            <div className="phactmi_logo" onClick={() => this.pathToHome()}>
              <img src={headerLogo} alt="phactMI Logo"></img>
              {/* <div className="phactmi_txt" dangerouslySetInnerHTML={{__html: phactmi_desc_txt}}></div> */}
            </div>
            <div>
              <Link to={"/search"} style={{padding: 0}}><img className="search-logo" src={searchIcon} alt="search-products" /></Link>
              <HeaderLabel className="d-xl-none">{this.displayPathName(pathname, links)}</HeaderLabel>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav>
                {
                  links.map((item, index) => {
                    if (item.redirect) {
                      return (
                        <Link className={item.customClass}
                          to="/"
                          onClick={() => this.memberportallink()}
                          key={index}
                        >
                          <div className="d-flex justify-content-center">
                            {item.label}
                          </div>
                        </Link>
                      )
                    }
                    else {
                      return (
                        <Link
                          className={(pathname.indexOf(item.link) >= 0)
                            ? item.customClass
                              ? `active-nav ${item.customClass}`
                              : "active-nav"
                            : tourStatus ? item.link === "/members" ? toolTipIndex === 7 ? "member-tour" :
                              item.customClass : item.customClass : item.customClass ? item.customClass : ""
                          }
                          to={item.link} key={index}
                          onClick={() => this.closeNav(item.action)}
                        >
                          <div className="d-flex justify-content-center">
                            {item.label}
                          </div>
                        </Link>
                      )
                    }
                  }
                  )
                }
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </Header>
    );
  }
}

export default connect(
  mapStateToProps
)(withRouter(HeaderComponent));
import React, { useEffect, useState, useRef } from "react";
import _head from "lodash/head";
import _get from "lodash/get";
import axios from "axios";
import _find from "lodash/find";
import _flow from "lodash/flow";
import _property from "lodash/property";
import _partialRight from "lodash/partialRight";
import _some from "lodash/some";
import { withRouter } from "react-router-dom";

import * as styled from "./style";
import searchResources from "../../../config/content/searchPage";
import WebLinkIcon from "../../../assets/images/web-link-icon.svg";
import ContactIcon from "../../../assets/images/phone-icon.svg";
import EmailIcon from "../../../assets/images/email-icon.svg";
import expandIcon from "../../../assets/images/expand-icon.svg";
import collapseIcon from "../../../assets/images/collapse-icon.svg";
import { endpoint } from "../../../config/app.config";
import NewsLetter from "../../../components/news-letter";
import SrdTitleIcon from "../../../assets/images/srd-icon.svg"
import PatientResourceIcon from "../../../assets/images/patient-resource.svg";
import accordianClose from "../../../assets/images/accordianClose.png";
import accordianOpen from "../../../assets/images/accordianOpen.png";
import store from "../../../store/store";
import { NEWS_LETTER_SUBSCRIPTION } from "../../home/actions";


const SearchSRDResults = (props) => {
  const [activeIndex, setActiveIndex] = useState([]);
  const [showSrdResult, setShowSrdResult] = useState(false);
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);

  let srdExpandTimeout;

  useEffect(() => {
    if (props.expanded) {
      setShowSrdResult(true)
    }

    return () => {
      clearTimeout(srdExpandTimeout);
    }
  }, [])

  useEffect(() => {
    let timer;
    const startTimer = () => {
      timer = setTimeout(() => {
        setSubscriptionSuccess(false)
      }, 3000);
    }
    if (props.subscriptionMessage) {
      setSubscriptionSuccess(true)
      startTimer();
    }

    return () => {
      clearTimeout(timer)
    }
  }, [props.subscriptionMessage])

  const {
    srds,
    onViewFullDocumentClick,
    srdFlag,
    zeroSearchResultMemberDetails,
    searchInput,
    role
  } = props;
  const {
    search: {
      result_txt,
      zero_txt,
      zero_result_des,
      connect_member,
      product_placrHolder,
      products_txt
    }
  } = searchResources;

  const onExpandCollapseSrd = (event, index, item) => {
    const isExpanded = activeIndex.includes(index) ? true : false
    setActiveIndex((prevState) => activeIndex.includes(index) ? [] : [index]);
    srdExpandCollapseAction(event, isExpanded, item, index)
  }

  const onSrdViewFullDocumentClick = (item) => {
    onViewFullDocumentClick(true, item, "");
  };

  const medicalInfoCLick = (ele, type) => {
    const { googleAnalytics: { zeroResultUrlTracking, patientResourceUrlTracking }} = searchResources
    window.dataLayer = window.dataLayer || [];
    if (type == "zeroResult") {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": props.role,
          "eventAction": zeroResultUrlTracking,
          "eventLabel": "",
          "cd1": props.role,
          "cd2": "Zero Result",
          "cd3": props.searchInput.userInput.keywordInput.trim(),
          "cd4": props.searchInput.userInput.userSearchInput.trim(),
          "cd5": ele.companyName,
          "cd8": "Null",
          "cd15": ele.linkLabel
        }
      })
      window.open(`/redirect?url=${ele.medicalInformationLink}`, "_blank");
    } else if (type == "patientResource") {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": props.role,
          "eventAction": patientResourceUrlTracking,
          "eventLabel": "",
          "cd1": props.role,
          "cd2": "Patient Resource",
          "cd3": props.searchInput.userInput.keywordInput.trim(),
          "cd4": props.searchInput.userInput.userSearchInput.trim(),
          "cd5": ele.companyName,
          "cd8": "Null",
          "cd15": ele.linkLabel
        }
      })
      window.open(`/redirect?url=${ele.patientLink}`, "_blank");
    }
  };

  const trackContactNo = (ele, type) => {
    const { googleAnalytics: { zeroResultContactTracking, patientResourceContactTracking }} = searchResources
    window.dataLayer = window.dataLayer || [];
    if (type == "zeroResult") {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": props.role,
          "eventAction": zeroResultContactTracking,
          "eventLabel": "",
          "cd1": props.role,
          "cd2": "Zero Result",
          "cd3": props.searchInput.userInput.keywordInput.trim(),
          "cd4": props.searchInput.userInput.userSearchInput.trim(),
          "cd5": ele.companyName,
          "cd8": "Null",
          "cd15": ele.contactNumber
        }
      })
    } else if (type == "patientResource") {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": props.role,
          "eventAction": patientResourceContactTracking,
          "eventLabel": "",
          "cd1": props.role,
          "cd2": "Patient Resource",
          "cd3": props.searchInput.userInput.keywordInput.trim(),
          "cd4": props.searchInput.userInput.userSearchInput.trim(),
          "cd5": ele.companyName,
          "cd8": "Null",
          "cd15": ele.contactNumber
        }
      })
    }
  }

  const trackEmail = (ele, type) => {
    const { googleAnalytics: { zeroResultEmailTracking, patientResourceEmailTracking }} = searchResources
    window.dataLayer = window.dataLayer || [];
    if (type == "zeroResult") {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": props.role,
          "eventAction": zeroResultEmailTracking,
          "eventLabel": "",
          "cd1": props.role,
          "cd2": "Zero Result",
          "cd3": props.searchInput.userInput.keywordInput.trim(),
          "cd4": props.searchInput.userInput.userSearchInput.trim(),
          "cd5": ele.companyName,
          "cd8": "Null",
          "cd15": ele.email
        }
      })
    } else if (type == "patientResource") {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": props.role,
          "eventAction": patientResourceEmailTracking,
          "eventLabel": "",
          "cd1": props.role,
          "cd2": "Patient Resource",
          "cd3": props.searchInput.userInput.keywordInput.trim(),
          "cd4": props.searchInput.userInput.userSearchInput.trim(),
          "cd5": ele.companyName,
          "cd8": "Null",
          "cd15": ele.email
        }
      })
    }
  }

  const displayZeroMessage = (data) => {
    let updatedData = [];
    if (
      searchInput.userInput.userSearchInput.toLowerCase().includes("dupixent")
    ) {
      const dupixentData = [];
      const nonDupixentData = [];
      data.map((dt) =>
        dt.companyName.toLowerCase().includes("regeneron")
          ? dupixentData.push(dt)
          : nonDupixentData.push(dt)
      );
      updatedData = [...dupixentData, ...nonDupixentData];
    } else {
      updatedData = [...data];
    }
    return updatedData.map((ele, index) => {
      return (
        <div key={index} className="patient-mir-info">
          <div className="member-name-details">
            <span
              dangerouslySetInnerHTML={{ __html: `${product_placrHolder} ` }}
            ></span>
            <span
              dangerouslySetInnerHTML={{
                __html: `<b>${ele.companyName} </b>${products_txt} visit:`
              }}
            ></span>
          </div>
          {ele.linkLabel && <styled.MedicalLinkWrapper
            className="member-link"
            onClick={() => medicalInfoCLick(ele, "zeroResult")}
          >
            <img
              src={WebLinkIcon}
              alt="web-link"
              className="member-link-icon"
            />
            <strong onClick={() => medicalInfoCLick(ele, "zeroResult")}>{ele.linkLabel}</strong>
          </styled.MedicalLinkWrapper>}
          {ele.contactNumber && ele.contactNumber.length > 0 && (
            <div className="member-link">
              <img
                src={ContactIcon}
                alt="contact-number"
                className="member-contact-icon"
              />
              <span
                onClick={() => trackContactNo(ele, "zeroResult")}
                dangerouslySetInnerHTML={{
                  __html: `<a href="tel://${ele.contactNumber}"><strong>${ele.contactNumber}</strong></a> `
                }}
              ></span>
            </div>
          )}
          {ele.email && ele.email.length > 0 && (
            <div className="member-link">
              <img
                src={EmailIcon}
                alt="email"
                className="member-contact-icon"
              />
              <span
                onClick={() => trackEmail(ele, "zeroResult")}
                dangerouslySetInnerHTML={{
                  __html: `<a href="mailto:${ele.email}"><strong>${ele.email}</strong></a> `
                }}
              ></span>
            </div>
          )}
        </div>
      );
    });
  };

  const displayMIRMsgForPatient = (data) => {
    let updatedData = [];
    if (searchInput.userInput.userSearchInput.toLowerCase().includes("dupixent")) {
      const dupixentData = [];
      const nonDupixentData = [];
      data.map(dt =>
        dt.companyName.toLowerCase().includes("regeneron") ? dupixentData.push(dt) : nonDupixentData.push(dt)
      );
      updatedData = [...dupixentData, ...nonDupixentData];
    } else {
      updatedData = [...data]
    }

    return (
      <><div className="patient-mir-msg-title">
        <span
          dangerouslySetInnerHTML={{__html: "The Information on PhactMI is intended only for US healthcare professionals. For further information for patients, please contact:"}}>
        </span></div>
      {updatedData.map((ele, index) => {
        return (
          <div key={index} className="patient-mir-info">
            <p className="member-name" dangerouslySetInnerHTML={{__html : `<b>${ele.companyName}</b>`}}></p>
              {ele.patientLinkLabel && <styled.MedicalLinkWrapper className="member-link" onClick={() => medicalInfoCLick(ele, "patientResource")}>
              <img src={WebLinkIcon} alt="web-link" className="member-link-icon" /><strong onClick={() => medicalInfoCLick(ele, "patientResource")}>{ele.patientLinkLabel}</strong>
            </styled.MedicalLinkWrapper>}{
              ele.contactNumber &&
            ele.contactNumber.length > 0 &&
            <div className="member-link">
              <img src={ContactIcon} alt="contact-number" className="member-contact-icon" />
              <span
                onClick={() => trackContactNo(ele, "patientResource")}
                dangerouslySetInnerHTML={{__html: `<a href="tel://${ele.contactNumber}"><strong>${ele.contactNumber}</strong></a> `}}>
              </span>
            </div>
            }
            {
              ele.email &&
            ele.email.length > 0 &&
            <div className="member-link">
              <img src={EmailIcon} alt="email" className="member-contact-icon" />
              <span
                onClick={() => trackEmail(ele, "patientResource")}
                dangerouslySetInnerHTML={{__html: `<a href="mailto:${ele.email}"><strong>${ele.email}</strong></a> `}}>
              </span>
            </div>
            }
          </div>
        )
      })}
      </>
    )
  }

  const getSessionId = () => {
    let sessionId = localStorage.getItem("_sid");
    if (!sessionId) {
      sessionId = sessionStorage.getItem("_sid");
      if (!sessionId) {
        sessionId = "NA";
      }
    }
    return sessionId;
  };

  const srdExpandCollapseAction = (event, isExpanded, item, index) => {
    const GADegrees = ["MD/DO", "NP/APN", "RPh/PharmD", "PA", "RN", "NE", "PT"];
    const { getSummary, searchInput, clearSummary, usersearchQuery } = props;
    const { userInput: {userSearchInput, keywordInput} } = searchInput;

    srdExpandTimeout = setTimeout(() => {
      if (index === 0) {
        window.scrollTo(0, 0)
      } else {
        const desiredIndex = index - 1
        const clickedDocument = document.getElementById(`srd-title-${desiredIndex}`);
        clickedDocument.scrollIntoView();
      }
    }, 300);

    if (!isExpanded) {
      if (!item.id || !userSearchInput || !keywordInput || !item.sourceDisplayName) {
        return;
      }

      const sessionId = getSessionId();
      axios
        .post(`${endpoint}/api/search/substances/summaries/${item.id}`, {
          docId: item.id,
          action: "summary",
          application: "web-browser",
          product: userSearchInput.trim(),
          searchTerm: keywordInput.trim(),
          url:`${endpoint}${props.location.pathname}`,
          sourceDisplayName: item.sourceDisplayName,
          sessionId
        }).then(data => {
          return data
        }).catch(error => {
          throw error
        })
    }

    const { role } = props;
    let degreeRole = role;
    if (role.toLowerCase().includes("patient") || role.toLowerCase().includes("consumer") || role.toLowerCase().includes("caregiver")) {
      degreeRole = "PT"
    }
    const { title } = item;
    const { googleAnalytics: { srdexpandclick_txt, srdcollapseclick_txt } } = searchResources;

    window.dataLayer = window.dataLayer || [];
    // if (index !== null) {
    //   if (item.summaryUrl) {
    //     const sessionId = getSessionId();
    //     const summaryUrl = item.summaryUrl + `&sessionId=${sessionId}`;
    //     getSummary(summaryUrl);
    //   }
    //   else {
    //     try {
    //       const url = item.id ? item.id.replace(" ", "+") : "";
    //       const documentUrl = item.documentUrl ? item.documentUrl : null;
    //       const prefix = documentUrl ? documentUrl.split("?") ? documentUrl.split("?")[1] : null : null;
    //       if (url && prefix) {
    //         const prefixUrl = "/substances/summaries/"+ url +"?"+ prefix;
    //         getSummary(prefixUrl);
    //       }
    //     }
    //     catch (e) {
    //       clearSummary();
    //     }
    //   }
    // }
    // else {
    //   clearSummary();
    // }
    if (isExpanded) {
      if (GADegrees.includes(degreeRole)) {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventCategory": degreeRole,
            "eventAction": srdcollapseclick_txt,
            "eventLabel": title,
            "cd1": degreeRole,
            "cd2": srdcollapseclick_txt,
            "cd3": title, // title
            "cd4": usersearchQuery,
            "cd5": item.sourceDisplayName,
            "cd8": "Null"
          }
        })
      }
      else {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventCategory": role,
            "eventAction": srdcollapseclick_txt,
            "eventLabel": title,
            "cd1": "Other",
            "cd2": srdcollapseclick_txt,
            "cd3": title,
            "cd4": usersearchQuery,
            "cd5": item.sourceDisplayName,
            "cd8": role
          }
        })
      }
    }
    else {
      if (GADegrees.includes(role)) {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventCategory": degreeRole,
            "eventAction": srdexpandclick_txt,
            "eventLabel": title,
            "cd1": degreeRole,
            "cd2": srdexpandclick_txt,
            "cd3": title, // title
            "cd4": usersearchQuery,
            "cd5": item.sourceDisplayName,
            "cd8": "Null"
          }
        })
      }
      else {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventCategory": role,
            "eventAction": srdexpandclick_txt,
            "eventLabel": title,
            "cd1": "Other",
            "cd2": srdexpandclick_txt,
            "cd3": title, // title
            "cd4": usersearchQuery,
            "cd5": item.sourceDisplayName,
            "cd8": role
          }
        })
      }
    }
    // this.setState({
    //   piExpandedIndex: null,
    //   srdExpandedIndex: index,
    //   srd_current_index: ide
    // }, this.scrollToContentSrd(event, ide));

  }

  const onMemberNameClick = (memberName) => {
    let matchedCompany = null;
    matchedCompany = props.manufacturerDetails ?
      _find(props.manufacturerDetails.data, record => (_find(record.searchCompanyName, itm => (itm.toLowerCase().includes(memberName.toLowerCase()))))) : null;
    const companyId = matchedCompany ? matchedCompany.urlTitle : null;
    props.onSourceClick(companyId)
  }

  const submitClick = (email) => {
    const { email_send_type } = searchResources;
    if (email) {
      store.dispatch({
        type: NEWS_LETTER_SUBSCRIPTION,
        payload: {
          email: email,
          type: email_send_type
        }
      })
    }
  }

  const displayComingsoonMsg = () => {
    const { noSrdResults: { email_heading_non_member, no_product_message},
      search: { non_member_placeholder }} = searchResources;
    const { subscriptionMessage, piResults } = props;

    if (piResults && piResults.length > 0) {
      return (
        <React.Fragment>
          <styled.NonMemberMessage>
            {non_member_placeholder}
          </styled.NonMemberMessage>
          <styled.ImgDesc>
            <styled.ImgDescContainer>
              <styled.ImgDesc2>{email_heading_non_member}</styled.ImgDesc2>
            </styled.ImgDescContainer>
          </styled.ImgDesc>
          <NewsLetter clickSubmit={submitClick} fromSearchpage={true} message={subscriptionMessage}
            subscriptionSuccess={subscriptionSuccess}></NewsLetter>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          <styled.NoProductMessage>
            {no_product_message}
          </styled.NoProductMessage>
        </React.Fragment>
      )
    }
    // }
  }

  return (
    <styled.ResultsContainer className=".srd">
      <styled.SectionTitle>
        <img src={role == "NE" ? PatientResourceIcon : SrdTitleIcon} alt="srd-list" /><span>
          {role == "NE" ? "Patient Resources" : `Response(s) to your Question (${srds.length})` }
        </span><img src={showSrdResult ? accordianClose : accordianOpen} onClick={() => setShowSrdResult(prevState => !prevState)} className="expand-close-icon" />
      </styled.SectionTitle>
      <styled.DataContainer showSrdResult={showSrdResult}>
        {srds.length > 0 && role !== "NE" &&
          srds.map((srd, index) => {
            return (
              <styled.DataAccordion className="search-accordion" key={srd.id}>
                <styled.DataAccordionTitle>
                  <styled.DataAccordionTitleText id={`srd-title-${index}`} onClick={() => onSrdViewFullDocumentClick(srd)}>
                    {srd.title}
                  </styled.DataAccordionTitleText>
                  {srd.snippet.summary.length > 0 && <button type="button" onClick={(event) => onExpandCollapseSrd(event, index, srd)}>
                    <img src={activeIndex.includes(index) ? collapseIcon : expandIcon} alt={index === activeIndex ? "collapse" : "expand"} />
                  </button>}
                </styled.DataAccordionTitle>
                <styled.DataAccordionTitleContent>
                  LAST UPDATE: {srd.lastUpdatedDate}
                </styled.DataAccordionTitleContent>
                <styled.DataAccordionFooter>
                  <styled.DataAccordionTitleSubContent>
                    SOURCE: <span onClick={() => onMemberNameClick(srd.sourceDisplayName)}>{srd.sourceDisplayName}</span>
                  </styled.DataAccordionTitleSubContent>
                  <button onClick={() => onSrdViewFullDocumentClick(srd)} id="viewDocument-btn-srd">
                    VIEW FULL DOCUMENT
                  </button>
                </styled.DataAccordionFooter>
                { activeIndex.includes(index) && <styled.AccordionExpandContent usage="srd"
                  // ref={elm => { innerContent = elm }}
                  dangerouslySetInnerHTML={{__html: _get(srd.snippet, _head(Object.keys(srd.snippet))) }}
                />}
              </styled.DataAccordion>
            );
          })}
        {srds && srds.length === 0 && role !== "NE" && (
          <styled.SRDWrapper>
            <div className="mir-desc-patient">
              {srdFlag ? (
                <React.Fragment>
                  <styled.NoResultWrapper>
                    <styled.ZeroResult>
                      <h2>{result_txt}</h2>
                      <span className="zero_txt">{zero_txt}</span>
                    </styled.ZeroResult>
                    <p>
                      {zero_result_des} <br /> {connect_member}
                    </p>
                    {zeroSearchResultMemberDetails &&
                      displayZeroMessage(zeroSearchResultMemberDetails)}
                  </styled.NoResultWrapper>
                </React.Fragment>
              ) : (
                displayComingsoonMsg()
              )}
            </div>
          </styled.SRDWrapper>
        )}
        {role == "NE" && (
          <styled.SRDWrapper>
            <div className="mir-desc-patient">
              {srdFlag ? (
                <React.Fragment>
                  <styled.NoResultWrapper>
                    {zeroSearchResultMemberDetails &&
                      displayMIRMsgForPatient(
                        zeroSearchResultMemberDetails
                      )}
                  </styled.NoResultWrapper>
                </React.Fragment>
              ) : (
                displayComingsoonMsg()
              )}
            </div>
          </styled.SRDWrapper>
        )}
      </styled.DataContainer>
    </styled.ResultsContainer>
  );
};

export default withRouter(SearchSRDResults);
/* eslint-disable max-len */
import science from "../../assets/images/Science.png";
import doctor from "../../assets/images/Doctor.png";
import book from "../../assets/images/Book.png";
import searchIcon from "../../assets/images/Search_icon.png";
import clearIcon from "../../assets/images/clear.png";
import FacebookLogo from "../../assets/images/facebook-logo.png";
import LinkedinLogo from "../../assets/images/linkedin-logo.png";
import TwitterLogo from "../../assets/images/twitter-logo.png";
import ASPLogo from "../../assets/images/asplogo.png";
import BadgeLogo from "../../assets/images/badge.png";
import GeneralErrorLogo from "../../assets/images/generalerror.png";
import closeGreen from "../../assets/images/close-green.png";
import closeWhite from "../../assets/images/close-white.png";
import phactmiGreenLogo from "../../assets/images/phactmi_green_logo.png";
import ErrorLogo from "../../assets/images/Error.png";
import SearchDatabase from "../../assets/images/searchDatabase.png";
import HumanExpert from "../../assets/images/humanExpert.png";
import DrugLogo from "../../assets/images/drug-information-db.png";

export default {
  mainHeading1_txt: "Your primary source for current, ",
  mainHeading2_txt: "evidence-based drug information ",
  mainHeading3_txt: "to support effective clinical decision making",
  email_send_type: "PhactMiUpdates",
  drug_logo: DrugLogo,
  drug_logo_alt: "drug_logo",
  drug_txt: "The Drug Information Database",
  partial_drug_txt: "The Drug Information",
  database_txt: "Database",
  search: {
    heading_txt:
      "Search over 3,000 product labels and drug information responses",
    button_txt: "Search",
    icon: searchIcon,
    clearIcon: clearIcon,
    clearIcon_alt_txt: "clear",
    Placeholder_txt: "Search by product name",
    Placeholder_forkeyword_txt: "Enter your question",
    error_logo: ErrorLogo,
    error_logo_alt: "error",
    error_message: "Please enter / select a product first.",
    error_message_search_term:
      "A search term must be entered in order to continue"
  },
  codeOfPracticeButtonTxt: "LEARN MORE ABOUT OUR CODE OF PRACTICE ",
  learnMore: "Learn More",
  searchDBLogo: SearchDatabase,
  humanExpertLogo: HumanExpert,
  searchDBAltTxt: "search database logo",
  humanExpertAltTxt: "human expert logo",
  searchDbTitle: "Self-Search Database",
  searchDbDesc:
    "Looking for drug information on US approved products? <br>Use the self-search database to find:",
  searchDbContent1:
    "Scientific responses to healthcare professionals’ questions from our member companies",
  searchDbContent2: "Interactive US approved product labels",
  searchDbButtonTxt: "SEARCH DRUG INFORMATION",
  expertDbButtonTxt: "MEMBERS CONTACT INFORMATION",
  expertTitle: "Call a Human Expert",
  expertDesc:
    "Need assistance finding the best clinical drug information for your patient case?",
  expertContent1:
    "Let us point you in the right direction and connect you with a clinical expert within our member companies",
  provide: {
    heading_content:
      "phactMI is a <span>non-profit organization</span> established by Drug Information experts across the pharmaceutical industry. Our goal is to provide healthcare professionals with the most accurate, up-to-date drug information.",
    heading1_txt: "What We Provide?",
    content: [
      {
        img_src: science,
        img_alt_txt: "Clinical Medical Information",
        img_txt: "Clinically Supported and Unbiased Medical Information"
      },
      {
        img_src: doctor,
        img_alt_txt: "Developed by Medical Experts",
        img_txt: "Developed by Highly Credentialed Medical Experts"
      },
      {
        img_src: book,
        img_alt_txt: "Scientifically-Accurate Medical Information",
        img_txt: "Comprehensive, Current and Scientifically-Accurate"
      }
    ]
  },
  headerLinks: [
    {
      label: "About",
      link: "/about"
    },
    {
      label: "Members",
      link: "/members"
    },
    // {
    //   label: "COVID-19 Resource",
    //   link: "/covid-19"
    // },
    {
      label: "News",
      link: "/news"
    },
    {
      label: "Publications",
      link: "/publications"
    },

    /* {
      label: "Careers",
      link: "/careers"
    }, */

    {
      label: "Search",
      link: "/search",
      customClass: "search-header"
    },
    {
      label: "Member Portal",
      link: "/login",
      customClass: "login-header",
      redirect: true
    }
  ],
  aboutSection: {
    title: "Code of Practice",
    img_alt_txt: "Code of Practice",
    body: "The Medical Information (MI) function within the pharmaceutical industry has a significant role in supporting the information needs of the medical community, patients, and caregivers. MI professionals from their respective companies have access to the most accurate and up-to-date data on their products. By sharing this information when requested.",
    link: "Learn more about us"
  },
  phactmi_desc_txt:
    "Pharma Collaboration for Transparent Medical Information<sup>TM</sup>",
  asp_logo: ASPLogo,
  asp_logo_alt_txt: "ashp Foundation - Bronze Member",
  mile_logo_alt_txt: "",
  corporate_partnership_txt: "Partnerships",
  badge_logo: BadgeLogo,
  badge_logo_alt_txt: "Badge logo",
  corporate_partnership_desc:
    "phactMI is proud to collaborate with outstanding organizations that support our mission and vision to help shape the future of Medical Information.",
  generalError: {
    logo: GeneralErrorLogo,
    alt_txt: "error_logo",
    heading: "Error!",
    description:
      "The application has encountered an error. Please navigate to the ‘Member Organizations’ page to find additional resources or contact <a href='mailto:admin@phactmi.org'>admin@phactmi.org</a> for additional assistance.",
    button_txt: "Go to Member Organizations"
  },
  closeGreenIcon: closeGreen,
  closeAltTxt: "close",
  closeWhiteIcon: closeWhite,
  phactmiLogo: phactmiGreenLogo,
  phactmiOverviewTxt: "phactMI Overview",
  youtubeLink:
    "https://www.youtube.com/embed/u3TJHlBgPLM?autoplay=1&showinfo=0&rel=0"
};
export const footerLinks = [
  {
    label: "Adverse Event Reporting",
    href: "https://www.accessdata.fda.gov/scripts/medwatch/index.cfm"
  },
  {
    label: "Privacy Policy",
    link: "/privacy-policy"
  },
  {
    label: "Terms/Conditions",
    link: "/terms-conditions"
  }
];

export const footerText =
  "The information provided on this site is intended only for health care professionals licensed in the United States and Puerto Rico.";
export const altText = "phactMI Logo";
export const footerCopyRightText = "phactMI. All rights reserved.";
export const backToTopAltTxt = "Back to top";
export const footerSocialMediaLogo = [
  {
    link: "https://twitter.com/phactMI",
    logo: TwitterLogo,
    logo_txt: "Twitter logo"
  },
  {
    link: "https://www.facebook.com/phactmi",
    logo: FacebookLogo,
    logo_txt: "Facebook logo"
  },
  {
    link: "https://www.linkedin.com/company/phactmi",
    logo: LinkedinLogo,
    logo_txt: "Linkedin logo"
  }
];

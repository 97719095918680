import styled from "styled-components";
import { mobileRange, tabletRange, ipadProRange} from "../../common/styles/breakpoints";
import { colors } from "../../common/styles/variables";

export const MainContainer = styled.div`
  display: block;
  @media ${mobileRange} {
    display: block;
  }
  @media ${tabletRange} {
    display: flex;
    justify-content: center;
  }
  @media ${ipadProRange} {
    display: flex;
    justify-content: center;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 268px;
  margin-top: 30px;
  border: 1px solid ${colors.dark_green};
  border-left: 6px solid ${colors.dark_green};
  height: 161px;
  text-align: center;
  padding: 10px;
  color: ${colors.sherpa_blue};
  cursor: pointer;
  h3 {
    font-size: 18px;
    font-weight: 600;
  }
  img {
    position: absolute;
    left: -4px;
    top: 0;
    width: 30px;
    height: 30px;
  }
`;

export const Description = styled.p`
  padding-top: 27px;
  font-size: 15px;
  width: 268px;
  color: ${colors.dove_gray};
  letter-spacing: -0.2px;
  text-transform: capitalize;
  text-align: left;
  @media ${tabletRange} {
    padding-left: 42px;
    font-size: 16px;
    width: 285px;
  }
  @media ${ipadProRange} {
    padding-left: 42px;
    font-size: 16px;
    width: 285px;
  }
`;


import React, {useState} from "react";
import PropTypes from "prop-types";
import AdditionalResourceCard from "../additional-resources-card/index";
import * as styled from "./styles";
import Accordion from "../accordiantwo"

/**
 * Additional Resource function
 * @function AdditionalResources
 * @exports AdditionalResources
 */
const AdditionalResources = React.forwardRef(({ data, resources, history }, ref) => {
  const { header } = resources;
  const [currentIndex, setcurrentIndex] = useState(0);

  /**
   * Display tab function
   * @return {undefined}
   */
  const displayTabs = () => {
    return (
      <styled.TabWrapper>
        {
          data.map((element, index) => {
            return (
              <styled.Tab active={currentIndex === index} onClick={() => tabClick(index)} key={index}>
                {element.resourceCategory}
              </styled.Tab>
            )
          })
        }
      </styled.TabWrapper>
    )
  }

  /**
    * Display Card function
    * @returns {HTMLElement} element
    */
  const displayCards = () => {
    if (data[currentIndex]) {
      return (
        <styled.CardWrapper>
          {
            data[currentIndex].content ?
              data[currentIndex].content.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)).map((element, index) => {
                if (element.pageUrl == "/podcast") {
                  return;
                }
                return (
                  <styled.AdditonalCardContent key={index}>
                    <AdditionalResourceCard data={element} history={history}
                      newResource={new Date(element.createdDate).setDate((new Date(element.createdDate).getDate() + 30)) > new Date()}>
                    </AdditionalResourceCard>
                  </styled.AdditonalCardContent>
                )
              })
              : null
          }
        </styled.CardWrapper>
      )
    }
  }

  /**
   * Tab click function
   * @param {Number} index index
   * @returns {undefined}
   */
  const tabClick = (index) => {
    setcurrentIndex(index);
  }

  const renderMobileAccordion = () => {
    const accordionData = data.map((dt, currentIndex) => {
      return {
        title: dt.resourceCategory,
        content: data[currentIndex].content.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)).map((element, index) => {
          if (element.pageUrl == "/podcast") {
            return;
          }
          return (
            <styled.AdditonalCardContent key={index}>
              <AdditionalResourceCard data={element} history={history}
                newResource={new Date(element.createdDate).setDate((new Date(element.createdDate).getDate() + 30)) > new Date()}>
              </AdditionalResourceCard>
            </styled.AdditonalCardContent>
          )
        })
      }
    })
    return (
      <Accordion data={accordionData} />
    )
  }

  return (
    <styled.MainContainer ref={ref}>
      <styled.AdditionalResourceTitle>
        {header}
      </styled.AdditionalResourceTitle>
      <styled.ResourceContent>
        {displayTabs()}
        {displayCards()}
      </styled.ResourceContent>
      <styled.MobileResourceContent>
        {renderMobileAccordion()}
      </styled.MobileResourceContent>
    </styled.MainContainer>
  );
});

AdditionalResources.propTypes = {
  data: PropTypes.array,
  resources: PropTypes.object
};

export default React.memo(AdditionalResources);

import React from "react";
import PropTypes from "prop-types";
import { endpoint } from "../../config/app.config";
import * as styled from "./styles";
import NewTag from "../../assets/images/new-tag.png";

/**
 * Additional Resource Card function
 * @param {object} data data
 * @returns {HTMLElement} element
 */
const AdditionalResourceCard = React.memo(({ data, history, newResource }) => {

  /**
   * Card clicj function
   * @param {String} resourceId id of resource click
   * @param {String} resourceUrl url of resource
   * @param {String} pageUrl url of page if present
   * @returns {undefined}
   */
  const cardClick = (resourceId, resourceUrl, pageUrl) => {
    if (pageUrl) {
      history.push(pageUrl);
    }
    else if (resourceUrl) {
      window.open(resourceUrl)
    }
    else {
      window.open(`${endpoint}/api/assets/files/${resourceId}`);
    }
  }

  /**
   * Card Display function
   * @returns {html} html
   */
  const displayCard = () => {
    return (
      <React.Fragment>
        <styled.CardWrapper onClick={ () => cardClick(data.resourceId, data.resourceUrl, data.pageUrl)}>
          {newResource && <img src={NewTag} alt="new-resource" />}
          <h3 dangerouslySetInnerHTML={{__html: data.displayName}}>
          </h3>
        </styled.CardWrapper>
        {/* <styled.Description>
          {data.resourceTitle}
        </styled.Description> */}
      </React.Fragment>
    )
  }
  return (
    <styled.MainContainer>
      {displayCard()}
    </styled.MainContainer>
  )
})

AdditionalResourceCard.propTypes = {
  data: PropTypes.object
};

export default React.memo(AdditionalResourceCard);